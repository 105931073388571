<template>
  <div id="Header">
    <div class="top">
      <!-- <div class="nav_btn" id="return_top"  @click='nav_left'>
        <img src="@/assets/images/Header/navLeft.png" alt=""  />
      </div> -->
      <div class="" style="display:flex;flex-wrap:nowrap;align-items: center">
        <div @click="goback">
            <img src="../../assets/images/q2/permutation/back.png" alt="" style="width:30px">
        </div>
        <div
          class="personal"
          v-show="IndividualsShow == true"
          @click="nav_right"
        >
          
          <div class="personal_pic">
            <img :src="User.avatar" alt="" />
          </div>
        
          <div class="personal_data" >
            <p style="color:fff;font-size:12px">{{ User.name | StrIntercept }}</p>
            <!-- <p style="color:fff;font-size:12px"> ID:{{ User.id }}</p> -->
            <!-- <img v-if="User.vip_level" :src='require(`../../assets/images/Nav/wan_vip${User.vip_level}.png`)' alt=""> -->
          </div>
        </div>
      </div>
      <div class="header_logo" v-show="IndividualsShow == false">
        <img
          src="@/assets/images/985/home/logo.png"
          alt=""
          @click="RouteJump('/Home')"
        />
      </div>
      <div class="Btns" v-show="IndividualsShow == false">
        <img src="../../assets/images/985/home/login.png" alt="" @click="SignShow = true">
        <img src="../../assets/images/985/home/zhu.png" alt=""  @click="zhuce()" >
        <!-- <p>注册</p>
        <p >登录</p> -->
      </div>
     
    
     <div class="beans" style="color:white" v-show="IndividualsShow == true"> 
        <span><img src="@/assets/images/public/Gold.png" alt="" style="width:12px" />{{User.bean}} <van-icon name="plus" @click="RouteJump('/Recharge')" /></span>
        <span><img src="@/assets/images/mall/integral.png" style="width:12px" alt="" />{{User.integral}}</span>
     </div>
    </div>
	<!-- Nav左侧栏 -->
    <van-popup
      id="van_left_popup"
      v-model="NavShow"
      :get-container="getContainer"
      position="left"
      :style="{ width: '70%', height: '100%' }"
    >
      <div class="van_lefts">
        <div
          class="Nav_item"
          :class="{ active: this.$route.name == 'Home' }"
          @click="RouteJump('/Home')"
        >
          <img src="@/assets/images/Nav/Csgo.png" alt="" />
          <p>首页</p>
        </div>
        <div
          class="Nav_item"
          :class="{ active: this.$route.name == 'box' }"
          @click="RouteJump('/box')"
        >
          <img src="@/assets/images/Nav/ICONS.png" alt="" />
          <p>开箱</p>
        </div>
        <div
          class="Nav_item"
          :class="{ active: this.$route.name == 'Jqiu' }"
          @click="RouteJump('/Jqiu')"
        >
          <img src="@/assets/images/Nav/ICONS.png" alt="" />
          <p>金球</p>
        </div>
        <div
          class="Nav_item"
          :class="{ active: this.$route.name == 'VIP' }"
          @click="RouteJump('/VIP')"
        >
          <img src="@/assets/images/Nav/nav8.png" alt="" />
          <p>VIP</p>
        </div>
        <div
          class="Nav_item"
          :class="{ active: this.$route.name == 'Activity' }"
          @click="RouteJump('/Activity')"
        >
          <img src="@/assets/images/Nav/MFbox.png" alt="" />
          <p>免费白嫖</p>
        </div>

        <div
          class="Nav_item"
          :class="{ active: this.$route.name == 'Battle' }"
          @click="RouteJump('/Battle')"
        >
          <img src="@/assets/images/Nav/BoxVs.png" alt="" />
          <p>盲盒对战<img
              src="	https://181skins.com/assets/fire.4244f5f5.gif"
              alt=""
            /></p>
        </div>
        <div
          class="Nav_item"
          :class="{ active: this.$route.name == 'Replacement' }"
          @click="RouteJump('/Replacement')"
        >
          <img src="@/assets/images/Nav/SpMall.png" alt="" />
          <p>炼金合同</p>
        </div>
       
        <div
          class="Nav_item"
          :class="{ active: this.$route.name == 'Lucky' }"
          @click="RouteJump('/Lucky')"
        >
          <img src="@/assets/images/Nav/Lucky.png" alt="" />
          <p>幸运追梦</p>
        </div>
        <div
          class="Nav_item"
          :class="{ active: this.$route.name == 'Mall' }"
          @click="RouteJump('/Mall')"
        >
          <img src="@/assets/images/Nav/Mall.png" alt="" />
          <p>饰品商城</p>
        </div>
        <div
          class="Nav_item"
          :class="{ active: this.$route.name == 'Roll' }"
          @click="RouteJump('/Roll')"
        >
          <img src="@/assets/images/Nav/Roll.png" alt="" />
          <p>ROLL福利</p>
        </div>
       
      </div>

      <!-- 	<div class="Nav_item" :class="{'active' : this.$route.name == 'RushPurchase'}" @click="RouteJump('/RushPurchase')">
				<img src="@/assets/images/Nav/SpMall.png" alt="">
				<p>抢卡</p>
			</div> -->
    </van-popup>

    <!-- Nav右侧栏 -->
    <van-popup
      id="van_right_popup"
      v-model="PerNavShow"
      position="left"
      :style="{ width: '50%', height: '100%' }"
    >
      <div class="van_rights">
        <div class="PreTop">
          <div class="Pre_img">
            <img :src="User.avatar" alt="" />
          </div>
          <div class="Pre_name">{{ User.name }}</div>
          <div class="Pre_email">{{ User.email }}</div>
          <div class="Pre_info">
            <div class="Info_box">
              <img src="@/assets/images/public/Gold.png" alt="" />
              <p style="color: #f1bc60">{{ User.bean }}</p>
            </div>
            <div class="Info_box">
              <img
                class="Info_box_img"
                src="@/assets/images/Nav/Vip.png"
                alt=""
                @click="RouteJump('/Extension')"
              />
              <!-- <p style="color: #ffba2e;">{{User.promotion_level}}</p> -->
            </div>
          </div>
          <div class="Recharge_btn" @click="RouteJump('/Recharge')">
            点击充值
          </div>
        </div>
        <div
          class="Nav_item"
          :class="{ active: this.$route.name == 'Me' }"
          @click="RouteJump('/Me')"
        >
          <img src="@/assets/images/Nav/PreInfo.png" alt="" />
          <p>个人中心</p>
        </div>
        <div
          class="Nav_item"
          :class="{ active: this.$route.name == 'Warehouse' }"
          @click="RouteJump('/Warehouse')"
        >
          <img src="@/assets/images/Nav/PreBb.png" alt="" />
          <p>个人背包</p>
        </div>
        <div
          class="Nav_item"
          :class="{ active: this.$route.name == 'Extension' }"
          @click="RouteJump('/Extension')"
        >
          <img src="@/assets/images/Nav/PreTg.png" alt="" />
          <p>会员推广</p>
        </div>
        <div
          class="Nav_item"
          :class="{ active: this.$route.name == 'Changepass' }"
          @click="RouteJump('/Changepass')"
        >
          <img src="@/assets/images/Nav/PreXg.png" alt="" />
          <p>修改密码</p>
        </div>
        <div
          class="Nav_item"
          :class="{ active: this.$route.name == 'vipActive' }"
          @click="RouteJump('/vipActive')"
        >
          <img src="@/assets/images/Nav/PreXg.png" alt="" />
          <p>奖励明细</p>
        </div>
        <div class="Nav_item" @click="BackUp">
          <img src="@/assets/images/Nav/PreTc.png" alt="" />
          <p>退出登录</p>
        </div>
      </div>
    </van-popup>
    <!-- <div class="NavHeader-bg"></div> -->

    

    <!-- 登录遮罩层 -->
    <van-overlay :show="SignShow" @click="SignShow = false">
      <div class="wrapper" @click.stop>
        <div class="LogOut_box">
          <div class="close" @click="CloseSign">
            <img src="@/assets/images/Nav/close.png" alt="" />
          </div>
          <div class="tit tits" style="display: flex; justify-content: space-around">
            <div @click="phoneCode = false" >登录</div>
            <div @click="phoneCode = true" >验证码登录</div>
          </div>
          <van-field v-model="mobile" type="text" label="手机号"  placeholder="请输入账号" />
          <van-field
            v-if="phoneCode"
            v-model="verify"
            center
            clearable
            label="验证码"
            placeholder="请输入验证码"
          >
            <template #button v-if="phoneCode">
              <van-button
                size="small"
                type="primary"
                v-show="VerCodeShow == false"
                @click="VerCodeSend(2)"
                >发送验证码
              </van-button>
              <van-button
                size="small"
                type="primary"
                v-show="VerCodeShow == true"
              >
                <van-count-down
                  millisecond
                  :time="Time"
                  format="ss"
                  :auto-start="false"
                  @finish="finish"
                  ref="countDown"
                />
              </van-button>
            </template>
          </van-field>
          <van-field
            v-else
            v-model="Password"
            type="password"
            maxlength="20"
            label="密码"
            placeholder="请输入不超过16位的密码"
          />
          <div class="Forget" @click="RouteJump('Changepass')">忘记密码</div>
          <div class="Sign_btn" @click="PostSignIn">登录</div>
        </div>
      </div>
    </van-overlay>

    <!-- 注册遮罩层 -->
    <van-overlay :show="RegShow" @click="RegShow = false">
      <div class="wrapper" @click.stop>
        <div class="LogOut_box">
          <div class="close" @click="CloseSign">
            <img src="@/assets/images/Nav/close.png" alt="" />
          </div>
          <div class="tit">注册</div>
          <van-field v-model="mobile" type="text" label="手机号" placeholder="请输入手机号" />
          <van-field
            v-model="Password"
            type="password"
            maxlength="20"
            label="密码"
            placeholder="请输入不超16位的密码"
          />
          <van-field
            v-model="Username"
            type="text"
            maxlength="10"
            label="用户名"
            placeholder="请输入昵称"
          />
          <van-field v-model="verify" center clearable label="验证码" placeholder="请输入验证码">
            <template #button>
              <van-button
                size="small"
                type="primary"
                v-show="VerCodeShow == false"
                @click="VerCodeSend(1)"
                >发送验证码
              </van-button>
              <van-button
                size="small"
                type="primary"
                v-show="VerCodeShow == true"
              >
                <van-count-down
                  millisecond
                  :time="Time"
                  format="ss"
                  :auto-start="false"
                  @finish="finish"
                  ref="countDown"
                />
              </van-button>
            </template>
          </van-field>
          <van-field
            v-model="InvitationCode"
            type="text"
            maxlength="10"
            label="邀请码"
            placeholder="请输入邀请码(选填)"
          />
          <div class="agreement">
            <van-switch v-model="AgrChecked" size="24px" />
            <p>我接受《用户条款和隐私政策》</p>
          </div>

          <div class="Sign_btn" @click="PostRegister">注册</div>
        </div>
      </div>
    </van-overlay>
    <van-notice-bar
      color="#fff"
      background="rgba(10,32,72,0.5)"
      left-icon="volume-o"
      style="font-size: 12px;"
      :text="noticebar"
    />
    <!-- <div class="indexPup" v-show="indexPup">
			<div class="indexPup-box">
				<img class="indexPup-con" @click="SignShowLogin" 
				src="../../assets/images/banner/shows.png" alt="" >
				<img class="indexPup-off" @click="indexPup = false" src="../../assets/images/banner/close.png" alt="">
			</div>
		</div>
		<div class="showTime" @click="showTime">
			<img src="../../assets/images/banner/shows.png" alt="">
		</div> -->
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import PubSwiper from "@/components/HeaderSwiper/index.vue";
import { SignIn, Register, PersonalInfo, PostSendSms,ApiInfo } from "@/network/api.js";

export default {
  name: "Header",
  data() {
    return {
      NavShow: false, //Nav左侧栏
      PerNavShow: false, //Nav右侧栏
      SignShow: false, //登录遮罩层
      RegShow: false, //注册遮罩层
      mobile: "", //账号
      Password: "", //密码
      Username: "", //用户名
      verify: "", //验证码
      InvitationCode: "", //邀请码
      VerCodeShow: false, //倒计时显示开关
      Time: 60 * 1000, //倒计时时间
      AgrChecked: false, //协议开关
      phoneCode: false, // 账号验证码切换
      indexPup: false,
      noticebar:'',
	 
    };
  },
  
  created() {
    this.GetApiInfo()
    this.InvitationCode = window.location.search.substr(5)
    console.log(this.InvitationCode);
    if (localStorage.getItem("token")) this.GetPersonalInfo();
    if(localStorage.getItem('token')){
				this.indexPup=false
		}else{
        this.indexPup=true
    }
   
  },
  filters: {
    StrIntercept(val) {
        return val.length > 6 ? val.substr(0, 5) + "..." : val;
    },
  },
  methods: {
    GetApiInfo(){
				ApiInfo().then((res) => {
					// console.log(res.data.data.static_file_url)
          this.noticebar = res.data.data.site_bulletin
          console.log(this.noticebar);
				})
			},
    zhuce(){
      this.RegShow = true
      this.InvitationCode = window.location.search.substr(5)
      console.log(this.InvitationCode);
    },
    goback(){
      this.$router.push('/home')
    },
    gonew(){

    },
    showTime(){
				this.indexPup = true
			},
    SignShowLogin(){
      this.$router.push('/Activity')
      this.indexPup = false
    },
  //点击左侧弹出左侧栏页面返回顶部
	nav_right(){
		this.PerNavShow = true; 
		this.NavShow = false;
		let top = document.documentElement.scrollTop || document.body.scrollTop
		// 实现滚动效果
		const timeTop = setInterval(() => {
			document.body.scrollTop = document.documentElement.scrollTop = top -= 50
			if (top <= 0) {
			clearInterval(timeTop)
			}
		}, 0)
	},
  //点击右侧用户弹出右侧栏页面返回顶部
	nav_left(){
		this.NavShow = true ;
		this.PerNavShow = false
		let top = document.documentElement.scrollTop || document.body.scrollTop
		// 实现滚动效果
		const timeTop = setInterval(() => {
			document.body.scrollTop = document.documentElement.scrollTop = top -= 50
			if (top <= 0) {
			clearInterval(timeTop)
			}
		}, 0)
	},
    //弹出框挂载节点
    getContainer() {
      return document.querySelector("#Header");
    },
    //Nav路由跳转
    RouteJump(str) {
      if (str == this.$route.fullPath) {
        this.$router.go(0);
      } else {
        this.$router.push(str);
        this.NavShow = false;
        this.PerNavShow = false;
      }
    },

    // 登录
    PostSignIn() {
      SignIn(this.mobile, this.Password, this.verify).then((res) => {
        // console.log(res)
        localStorage.setItem(
          "token",
          "Bearer" + " " + res.data.data.access_token
        );
        this.CloseSign();
        this.$notify({
          type: "success",
          message: "登录成功",
        });
        this.GetPersonalInfo();
      });
    },

    //邮箱验证码发送
    VerCodeSend(unm) {
      this.VerCodeShow = true;
      this.$refs.countDown.start();
      PostSendSms(this.mobile, unm).then((res) => {
        this.$notify({
          type: "success",
          message: "发送成功",
        });
      });
    },

    finish() {
      //重置倒计时
      this.VerCodeShow = false;
      this.$refs.countDown.reset();
    },

    //注册
    PostRegister() {
      if (this.AgrChecked == true)
      
        Register(
          this.mobile,
          this.Password,
          this.Username,
          this.verify,
          this.InvitationCode
        ).then((res) => {
          // console.log(res)
          this.$notify({
            type: "success",
            message: "注册成功",
          });
          this.CloseSign();
        });
      else {
        this.$notify({
          type: "danger",
          message: "请同意用户协议",
        });
      }
      let href = window.location.href
      const str = href.substr(href.indexOf("?")+1,href.length)
      const code = params[0].split('=')[1]
      console.log(code);
    },

    //个人信息
    GetPersonalInfo() {
      PersonalInfo().then((res) => {
        // console.log(res.data.message)
        this.PostUser(res.data.message);
        this.PostIndividualsShow(true);
      });
    },

    //关闭登录遮罩层
    CloseSign() {
      this.SignShow = false;
      this.Account = "";
      this.Password = "";
      this.Username = "";
      this.VerificationCode = "";
      this.InvitationCode = "";
      this.RegShow = false;
    },

    //退出登录
    BackUp() {
      this.PerNavShow = false;
      this.PostIndividualsShow(false);
      localStorage.clear();
      sessionStorage.clear();
      this.$router.go(0);
    },

    ...mapMutations(["PostUser", "PostIndividualsShow"]),
  },
  computed: {
    ...mapState(["User", "IndividualsShow"]),
  },
  components: {
    PubSwiper,
  },
};
</script>


<style lang="scss">
.beans{
  font-size: 12px !important;
  display: flex;
  flex-wrap: nowrap;
  span{
    margin-right: 5px;
    // background: #000;
    padding: 10px;
    padding-top: 5px;
    padding-bottom: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.showTime{
	position: fixed;
	left: 0.2rem;
	bottom: 0.5rem;
  z-index: 99;
	img{
		width: 100px;
	}
}
.indexPup{
	width: 100vw;
	height: 100vh;
	background: rgba(0, 0, 0, 0.7);
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 999;
	&-box{
		position: relative;
    top: 80px;
	}
	&-con{
		width: 300px;
	}
	&-off{
		position: absolute;
		top: 0px;
		right: 0px;
	}
}
#Header {
  width: 100%;
  margin: 0 auto;
  .top {
    margin: 0 auto;
    width: 350px;
    position: fixed;

    // left: 0;
    top: 0;
    z-index: 999;
    // width: 100%;
    height: 50px;
    // background: rgba(0, 0, 0, 0.1) !important;
    // background: rgb(30, 51, 86) !important;
    background: url('../../assets/images/985/home/bottomnav.png') no-repeat center;
    background-size: 100% 100%;

    // background: url(../../assets/images/Header/Header_Back.jpg) no-repeat center;
    background-size: cover;
    display: flex;
    // justify-content: space-between;
    align-items: center;
    // padding: 0 10px;
    box-sizing: border-box;
	// .tops{
	// 	position: relative;
	// }
    .nav_btn {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 25px;
        height: 25px;
      }
    }

    .header_logo {
      width: 150px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 15px;
      margin-right: 15px;

      img {
        max-width: 80%;
        max-height: 90%;
      }
    }

    .Btns {
      width: 140px;
      height: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      img{
        width: 70px;
      }
      p {
        width: 48px;
        height: 20px;
        border: 1px solid #ffba2e;
        border-radius: 10px;
        color: #ffba2e;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .personal {
      // margin: 0 auto;
      width: 105px;
      height: 50px;
      display: flex;
      // justify-content: space-between;
      align-items: center;
      .personal_data {
        width: 75px;
        text-align: left;
        margin: 0 auto;
        // border: 1px solid #cccccc4a;
        padding: 3px;
        height: 28px;
        margin-left: 2px;
        position: relative;
        img{
          position: absolute;
          width: 20px;
          right: 5px;
          top: 5px;
        }
        p {
          font-size: 8px;
          color: #fff;
          margin: 0 auto;
          padding: 1px;
          font-weight: 700;
        }

        span {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 12px;
          width: 50%;
          color: #f1bc60;
          margin: 0 auto;
          img {
            width: 15px;
          }
        }
      }

      .personal_pic {
        width: 35px;
        height: 35px;
        // border-radius: 50%;
        overflow: hidden;
        border: 1px solid #FD6C0F;
        box-sizing: border-box;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  #van_left_popup {
	// top: 0;
	// left: -20%;
  margin: 0 auto;
  }
  #van_right_popup {
    margin: 0 auto;
  }
  .van-popup {
    background:rgb(47, 49, 88);
    padding-top: 50px;

    box-sizing: border-box;
    // left: 30%;
    margin: 0 auto;
    // .nav_left{
    // 	width: 50%;

    // }
    .PreTop {
      width: 100%;
      .Pre_img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        overflow: hidden;
        margin: 0 auto;
        border: 2px solid #ffba2e;
        box-sizing: border-box;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .Pre_name {
        width: 100%;
        height: 20px;
        text-align: center;
        font-size: 12px;
        color: #fff;
        line-height: 20px;
        margin: 5px 0 2px;
      }

      .Pre_email {
        width: 100%;
        height: 15px;
        text-align: center;
        font-size: 10px;
        color: #b1b1b1;
        line-height: 15px;
      }

      .Pre_info {
        width: 100%;
        height: 50px;
        display: flex;
        margin: 15px 0;
        
        .Info_box {
          width: 50%;
          text-align: center;
          &:first-child {
            border-right: 1px solid hsla(0, 0%, 100%, 0.3);
          }
          &_img {
            width: 50px !important;
            height: 50px !important;
          }
          img {
            width: 20px;
            height: 20px;
            margin-top: 6px;
          }
          p {
            font-size: 14px;
            margin-top: 3px;
          }
        }
      }
      .Recharge_btn {
        width: 70%;
        height: 40px;
        background: rgb(24, 24, 69);
        margin: 0 auto;
        text-align: center;
        line-height: 40px;
        font-size: 14px;
        color: #fff;
        margin-bottom: 10px;
        margin: 0 auto ;
      }
    }

    .Nav_item {
      width: 100%;
      height: 48px;
      display: flex;
      align-items: center;
      margin: 0 auto ;
      //   padding-left: 25px;
      border-left: 4px solid transparent;
      box-sizing: border-box;
      //   justify-content: flex-start;
      &.active {
        border-left-color: #7581eb;
        background: #232749;
      }

      img {
        // width: 10px;
        margin: 0 auto ;
        height: 18px;
        left: 0;
      }

      p {
        font-size: 12px;
        margin: 0 auto ;
        width: 50%;
        color: #fff;
        margin-left: 10px;
      }
    }
  }

  .van-overlay {
    z-index: 10001;

    .wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 350px;
      .LogOut_box {
        width: 93%;
        padding: 20px;
        padding-top: 50px;
        padding-bottom: 40px;
        box-sizing: border-box;
        background: url('../../assets/images/985/home/logins.png') no-repeat center;
        background-size: 100% 100%;
        border-radius: 10px;
        position: relative;
        color: #fff !important;
        position: relative;

        .close {
          position: absolute;
          top: -20px;
          right: 10px;
          width: 20px;
          height: 20px;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            max-width: 100%;
            max-height: 100%;
          }
        }

        .tit {
          width: 100%;
          text-align: center;
          font-size: 22px;
          color: #fff;
          margin-bottom: 5px;
        }
        .tits{
          padding-bottom: 50px;
          padding-top: 1;
        }
        .van-cell {
          background: none;
          margin-top: 10px;

          .van-field__label {
            width: 50px;
            color: #fff;
          }

          .van-field__control {
            color: #fff;
          }
        }

        .van-cell::after {
          background: #000;
        }
      }

      .Forget {
        width: 100%;
        height: 40px;
        line-height: 40px;
        margin: 10px 0;
        padding-right: 20px;
        box-sizing: border-box;
        color: #a6a6a6;
        font-size: 12px;
        text-align: right;
      }
      .van-count-down {
        color: #fff;
        font-size: 14px;
      }
      .agreement {
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        p {
          font-size: 14px;
          color: #fff;
          margin-left: 5px;
        }
      }
      .Sign_btn {
        width: 90%;
        height: 44px;
        margin: 0 auto;
        background: #ffffff;
        border-radius: 6px;
        text-align: center;
        line-height: 44px;
        color: #000;
        font-size: 22px;
      }
    }
  }
}
.NavHeader-bg {
  margin-top: 1.4rem;
  // width: 100%;
  // height: 3rem;
  // background-size: 100%;
  // background-repeat: no-repeat;
  // background-position: center;
  // background-image: url('../../assets/images/af-1/bg.png');
}
.activess{
  color: blue;
}
</style>
